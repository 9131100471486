import './App.css';
import Translator from './Translator/Translator';
import ResponsiveAppFooter from './components/Footer';
import ResponsiveAppBar from './components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <ResponsiveAppBar />
    <div className="App">
      <Translator />
    </div>
    <ResponsiveAppFooter />
    <ToastContainer />
    </>
  );
}
 
export default App;
