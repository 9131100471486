import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function ResponsiveAppFooter() {
  return (
    <AppBar
      position="fixed"
      sx={{ top: 'auto', bottom: 0, height: '4em', backgroundColor: '#1976d2' }}
    >
      <Container maxWidth="xl">
        <Toolbar>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body1" color="inherit">
              © 2024
            </Typography>
            <Box sx={{ display: 'flex', gap: { xs: '1em', sm: '2em' }, flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}>
              <Typography variant="body1" color="inherit">
                KADRI Yousra Amina
              </Typography>
              <Typography variant="body1" color="inherit">
                KHERARBA Samira
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppFooter;
