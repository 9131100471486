import { useState } from "react";
import './Translator.css';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from "react-toastify";

const Translator = () => {

  /* Definned states for form inputs */
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [isTranslated, setIsTranslated] = useState();
  const [isLoading, setIsLoading] = useState(false);

/* Defined function to call Hugginface API */
      const translate = async () =>  {
        setIsLoading(true);
        try {
          /* Making call to API with Inference privileges  */
          const response = await fetch(
            "https://api-inference.huggingface.co/models/mina-kdr/FR_DZ_TRANSLATOR",
            {
              headers: { Authorization: `Bearer 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` , wait_for_model : true },
              method: "POST",
              body: JSON.stringify(inputText),
            }
          );
          if(response.error){
            toast.info(response.error || "Le modèle est actuellement en train de charger");
          }
          if(response){
            setIsLoading(false);
            setIsTranslated(true);
            const result = await response.json();
            console.log(result, 'translated word');
            /* Show output on the page */
            setOutputText(result[0]?.generated_text);
	          return result;
          }
          
        } catch (error) {
          setIsTranslated(false);
         
          console.error(error)
        }
      }

  const clearInput = () => {
    setInputText('');
    setOutputText('');
    setIsTranslated();
  }

  return (
    <section className="translator">
      <div className="row-wrapper">
        <div className="translator-container input-lang">
          <div className="top-row">
          <button
              className="btn btn-primary btn-translate"
              onClick={translate}
               disabled={isLoading}
              >
             Traduire
            </button>
          </div>
          <form className="input-form">
            <textarea
              className="text-box"
              placeholder="Saisir du texte (Français)"
              onChange={e => setInputText(e.target.value)}
              value={inputText}
            >
            </textarea>
            {
              inputText !== "" &&
              <AiOutlineClose
                className="icon-btn close-btn"
                onClick={clearInput}
              />
            }
          </form>
        </div>
        <div className="translator-container output-lang">
          <div className="top-row">
            <select
              disabled
              name="languages"
              id="languages"
              className="form-select form-select-sm"
            >
              <option value="ar">Daridja</option>
            </select>
          </div>
          <p className="text-box output-box">
            {
              isTranslated === false ?
                <span className="output-placeholder translation-error">Translation failed</span>
                :
                outputText === "" ?
                  <span className="output-placeholder"></span>
                  :
                  outputText
            }
          </p>
        </div>
      </div>
    </section>
  );
}

export default Translator;